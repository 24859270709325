.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  background-color: #f5f7f9;
}
.container .banner {
  height: 420px;
  width: 100%;
}
.container .banner img {
  height: 420px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  object-fit: cover;
}
.container .content {
  width: 1080px;
  margin: 30px 0 48px;
  padding: 0 8px;
  min-height: 682px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.container .content .filter {
  height: 32px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 9px;
}
.container .content .filter .filter-left {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}
.container .content .filter .filter-left .list-title {
  font-size: 24px;
  font-weight: 600;
  margin-right: 80px;
}
.container .content .filter .filter-left .filter-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
}
.container .content .filter .filter-left .filter-item .level {
  margin-right: 24px;
  font-size: 14px;
  font-weight: 600;
  color: #737373;
  position: relative;
}
.container .content .filter .filter-left .filter-item .level .level_click {
  color: #00BBBD;
}
.container .content .filter .filter-left .filter-item .level a {
  color: #737373;
}
.container .content .filter .filter-left .filter-item .level a:hover {
  color: #00BBBD;
}
.container .content .filter .filter-left .filter-item .level:after {
  content: "";
  width: 0;
  height: 2px;
  background: #00BBBD;
  position: absolute;
  top: 25px;
  left: 50%;
}
.container .content .filter .filter-left .filter-item .level:hover:after {
  left: 0;
  width: 100%;
}
.container .content .filter .more {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  color: #737373;
  cursor: pointer;
}
.container .content .filter .more:hover {
  color: #00BBBD;
}
.container .content .plan-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
